.wrapper {
  /* Should be bleed on small screens */
  @apply px-0 sm:px-8 mt-24 lg:mt-0;

  &:global(.is-narrow) {
    @apply mt-0;
  }
}

/* FIXME does this class every occur??? */
.single {
  @apply bg-summer-green-50 pb-13 mt-22;
  @apply lg:mt-0 lg:pb-0 lg:bg-transparent;
}

.innerWrapper {
  @apply lg:py-13 lg:px-10 lg:pl-0 xl:px-15 xl:pl-0 lg:ml-13;

  &:global(.no-padding) {
    @apply py-0;
  }

  &:global(.has-narrow-layout) {
    @apply mx-auto px-4 sm:px-0 !important;
    @apply overflow-clip;

    &:global(.layout-right) {
      & :global(.no-grid) {
        @apply sm:flex-row-reverse;
      }
      & :global(.image-wrapper) {
        @apply sm:mr-0 sm:ml-10 !important;
      }
    }

    & :global(.no-grid) {
      @apply flex gap-0 mx-auto justify-center items-center flex-col sm:flex-row;
    }

    & :global(.image-wrapper) {
      @apply mx-0 px-0 w-full sm:max-w-[170px] col-start-1 !important;
      @apply sm:mr-10 top-0 mb-0 !important;
    }

    & :global(.content-wrapper) {
      @apply pl-0 pr-0 !important;
      @apply w-full max-w-1xl flex-shrink;
    }
  }
}

.contentWrapper {
  @apply flex flex-col items-start justify-center px-4 sm:px-8 pt-8 pb-12 lg:p-0;

  &:global(.no-padding) {
    @apply pb-0;
  }
}

.meta {
  @apply text-sm mt-3.5 lg:mt-5;
}

.imageHolder {
  @apply px-4 sm:px-8 relative -top-12 -mb-12;
  @apply lg:top-0 lg:mb-0 lg:px-0 lg:-ml-13;
}

.figure {
  @apply relative h-full aspect-w-5 aspect-h-5 xl:aspect-w-6 image-has-hover-effect;
}

.contentGrid {
  @apply grid grid-cols-1 lg:grid-cols-2 gap-x-6 xl:gap-x-16;

  &:global(.no-image) {
    @apply grid-cols-1;
  }

  &:global(.image-small) {
    @apply lg:grid-cols-5;
    & :global(.image-wrapper) {
      @apply w-[50%] mx-auto;
      @apply lg:w-auto lg:-ml-13 lg:mr-0 lg:col-span-1;
    }
    & :global(.content-wrapper) {
      @apply lg:col-span-4 lg:pr-20;
    }
  }

  &:global(.image-medium) {
    @apply lg:grid-cols-3;
    & :global(.image-wrapper) {
      @apply w-[75%] mx-auto;
      @apply lg:w-auto lg:-ml-13 lg:mr-0 lg:col-span-1;
    }
    & :global(.content-wrapper) {
      @apply lg:col-span-2;
    }
  }
}

.image {
  @apply object-cover object-center w-full h-full;
}

/* Right layout - Image to the right */
.layout-right {
  & .innerWrapper {
    @apply lg:pl-10 lg:pr-0 lg:ml-0 lg:mr-13;
  }

  & .contentGrid {
    & .imageHolder {
      @apply lg:ml-0 lg:-mr-13 lg:col-start-2 lg:row-start-1;
    }

    &:global(.image-small) {
      & :global(.image-wrapper) {
        @apply lg:col-start-5;
      }
      & :global(.content-wrapper) {
        @apply lg:col-start-1;
      }
    }

    &:global(.image-medium) {
      & :global(.image-wrapper) {
        @apply lg:col-start-3;
      }
      & :global(.content-wrapper) {
        @apply lg:col-start-1;
      }
    }
  }
}

.layout-third {
  & .innerWrapper {
    @apply ml-0;
  }
}
