.wrapper {
  @apply overflow-hidden;

  & :global(.heading) {
    @apply text-2xl lg:text-2.5xl mb-5 md:mb-7.5 lg:mb-10;
  }

  & :global(.campaigns) {
    @apply grid grid-cols-1 gap-10;

    &:global(.cards-2) {
      @apply lg:grid-cols-2;
    }

    &:global(.cards-3) {
      @apply md:grid-cols-2 lg:grid-cols-3;
    }

    &:global(.cards-4) {
      @apply md:grid-cols-2 lg:grid-cols-4;
    }
  }
}

.link-w-hover {
  @apply ml-2 font-normal;

  background-image: linear-gradient(currentColor, currentColor);
  background-repeat: no-repeat;
  background-size: 100% 2px;
  background-position: 0% 90%;
  transition: all 0.3s ease-out;

  &:hover {
    background-size: 0% 2px;
  }
}

.card {
  & :global(.image-wrapper) {
    @apply aspect-w-1 aspect-h-1 bg-black-100/10 cursor-pointer;
    @apply relative mb-5;
  }

  & :global(h3) {
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 0% 1px;
    transition: background-size 0.3s;

    @apply font-sans inline text-1.5xl md:text-2xl lg:text-2.5xl;
  }

  & :global(p) {
    @apply py-2;
  }

  & :global(img) {
    transition: transform 1s cubic-bezier(0.395, 0.005, 0.19, 1);
  }

  &:hover {
    & h3 {
      background-size: 100% 1px;
    }

    & img {
      transform: scale(1.025);
    }
  }

  & :global(.teaser-link) {
    @apply block text-green-100 font-bold underline mt-2;
  }
}
