.playButton {
  @apply text-black-100;
  transform: translate(-50%, -50%);
}

.buttonAnimation {
  @apply transition duration-200 ease-linear transform -z-1;
}

.btnColor {
  @apply bg-summer-green-100;
}

.video {
  & img {
    transition: transform 1s cubic-bezier(0.395, 0.005, 0.19, 1);
  }

  &:global(.hover) {
    & img {
      transform: scale(1.025);
    }

    & [class*="buttonAnimation"] {
      transform: scale(1.1);
    }

    & [class*="overlay"] {
      @apply bg-black-100;
      transition: background-color 1s linear;
    }
  }
}

.overlay {
  @apply absolute z-10 w-full h-full bg-transparent opacity-40;
  transition: background-color 1s linear;
}
