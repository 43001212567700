/* theme/content-block */
.wrapper {
  @apply bg-white text-black-100 rounded;

  & :global(.inner-wrapper) {
    @apply p-8 flex flex-col flex-nowrap h-full;
  }

  & :global(div[data-block="core/heading"] > *) {
    @apply text-left;
  }

  & :global(div[data-block="core/button"]) {
    @apply mt-auto;
  }

  & :global(div[data-block="core/paragraph"]) {
    @apply ml-0 sm:max-w-[90%];
  }
}

.theme-lime {
  @apply bg-summer-green-50;
}

.theme-light-teal {
  @apply bg-dark-cyan-50;
}

.theme-dark-gray {
  @apply bg-black-100 text-white;
}

.single {
  & :global(div[data-block="core/button"]) {
    @apply mb-6;
  }

  & :global(.content-wrapper div:last-child) {
    @apply mb-0 pb-0;
  }
}
